import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {FaHeart, FaMedal, FaSmileBeam} from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

import AboutUsImg from "../assets/img/about-us.png"
import CTA from "../components/cta"


const AboutPage = () => (
  <StaticQuery
    query={graphql`
      query{
        allStrapiPages{
          edges {
            node {
              PageTitle
            }
          }
        }
      }
    `}

    render={data => (
      
    <Layout type="about">
        <SEO title="About us" />

        <section className="img-text-section" >

        <h1>Get to know us</h1>
          
          <div data-uk-grid>
            <div className="uk-width-1-2@m uk-text-center section-img-container">
              <img className="section-img " src={AboutUsImg} alt="SiteImg1"></img>
            </div>
    
    
            <div className="uk-width-1-2@m">
              <div className="section-content">
                <h2 className="section-title">What is fingersnap?</h2>
                <div className="section-subtitle"><b>Reprehenderit</b> officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">Reprehenderit officia magna sit excepteur cupidatat ea exercitation consequat eu incididunt quis commodo cillum cupidatat.</div>
                <div className="section-subtitle">
                <a href="#0" className="cta-primary-btn dark">More details</a>
                </div>
                
              </div>
            </div>
          </div>
        </section>

        <section className="features-short-section">

          <h1>Our core values</h1>

          <div className="uk-panel uk-text-center">
              <div className="header-features-container">
                { /* four cards */}
                <div className="header-features-card">
                  <FaHeart className="value-icon"/>
                  <h2>Empathy</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-features-card">
                  <FaMedal className="value-icon"/>
                  <h2>Integrity</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                <div className="header-features-card">
                  <FaSmileBeam className="value-icon"/>
                  <h2>Positivity</h2>
                  <p>Est magna laboris eu eu anim excepteur Lorem proident est elit eiusmod.</p>
                  <a href="#0" className="primary-btn dark">Start using</a>
                </div>

                

              </div>
            </div>

        </section>

        <CTA type="cta" url="#0"/>


      </Layout>

    )}
  
  />
)

export default AboutPage
